import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../Components/Utils/CustomSelect";
import { useDispatch } from "react-redux";
import Input from "../../Components/Utils/Input";
import {
  useGetCoursesQuery,
  useGetCollegesQuery,
  useGetCertificateQuery,
} from "./../../store/slices/apis/masterApi";
import AssameseKeyboard from "./../../Components/Utils/AssameseKeyboard";
import AssameseInput from "../../Components/Utils/AssameseInput";
import {
  useAddApplicationMutation,
  useGetApplicationQuery,
  useUpdateApplicationMutation,
  useLazyGetDataByRegNoQuery,
} from "./../../store/slices/apis/applicationApi";
import { toast } from "react-toastify";
import Loader from "../../Components/Utils/Loader";
import useDebounce from "../../hooks/useDebounce";
import axios from "axios";
import SearchInput from "../../Components/Utils/SearchInput";
export const CertificateCreateStep1 = ({ data, certificate_id, setTab }) => {
  console.log("CertificateCreateStep1", data?.step, data?.step > 1);

  const [isLoading, setLoading] = useState(false);
  const [query, setQuery] = useState(null);
  const debounceQuery = useDebounce(query, 1000);
  const formikRef = useRef();

  const [
    getDataByRegNo,
    {
      data: regData,
      isFetching: isFetchingGetRegData,
      isLoading: isLoadingGetRegData,
      isSuccess: isSuccessGetRegData,
      isError: isErrorGetRegData,
    },
  ] = useLazyGetDataByRegNoQuery();

  useEffect(() => {
    if (debounceQuery) {
      (async () => {
        try {
          getDataByRegNo(debounceQuery);
        } catch (err) {
        } finally {
        }
      })();
    }
  }, [debounceQuery]);

  useEffect(() => {
    if (isSuccessGetRegData) {
      if (formikRef.current) {
        if (regData) {
          const previousValues = formikRef.current.values;
          const newValues = {
            ...previousValues,
            name: regData.name || "",
            father_name: regData.father_name || "",
            // registration_no: regData.reg_no || "",
          };
          formikRef.current.setValues(newValues);
        } else {
          formikRef.current.setFieldValue("name", "");
          formikRef.current.setFieldValue("father_name", "");
        }
      }
    }
  }, [regData, isLoadingGetRegData, isSuccessGetRegData, isErrorGetRegData]);

  const [isSaving, setSaving] = useState(false);
  const [currentInput, setCurrentInput] = useState("");
  const [addApplication] = useAddApplicationMutation();
  const [updateApplication] = useUpdateApplicationMutation();
  const [isAssameseKeyboardVisible, setAssameseKeyboardVisibility] =
    useState(false);
  const {
    data: courses = [],
    isLoading: isLoadingGetCourses,
    isSuccess: isSuccessGetCourses,
    isError: isErrorGetCourses,
  } = useGetCoursesQuery();
  const {
    data: colleges = [],
    isLoading: isLoadingGetColleges,
    isSuccess: isSuccessGetColleges,
    isError: isErrorGetColleges,
  } = useGetCollegesQuery();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    course_id: Yup.number().required("Please select a degree"),
    // subject_id: Yup.number().required("Please select a subject"),
    final_sem: Yup.string().required(
      "Final semester/year examination date is required"
    ),
    date_of_passing: Yup.string().required("Date of passing is required"),
    registration_no: Yup.string().required("Registration no. is required"),
    name: Yup.string().required("Candidate Name is required"),
    father_name: Yup.string().required("Father's name is required"),
    college_id: Yup.string().required(
      "College/Department/Institute is required"
    ),

    assamese_declaration: Yup.boolean(),
    assamese_name: Yup.string().when("assamese_declaration", {
      is: (assamese_declaration) => assamese_declaration === false,
      then: () => Yup.string().required("Name is required"),
    }),

    re_assamese_name: Yup.string().when("assamese_declaration", {
      is: (assamese_declaration) => assamese_declaration === false,
      then: () =>
        Yup.string()
          .oneOf([Yup.ref("assamese_name"), null], "Name must match")
          .required("Confirm name is required"),
    }),
  });
  const [initialValues, setInitialValues] = useState({
    assamese_declaration: false,
    terms_declaration: false,
    stepone_declaration: false,
    certificate_id: certificate_id,
    course_id: "",
    final_sem: "",
    date_of_passing: "",
    registration_no: "",
    name: "",
    father_name: "",
    college_id: "",
    assamese_name: "",
    re_assamese_name: "",
    step: 2,
  });

  useEffect(() => {
    data &&
      setInitialValues({
        course_id: data?.course_id || "",
        final_sem: data?.final_sem ?? "",
        date_of_passing: data?.date_of_passing ?? "",
        registration_no: data?.registration_no ?? "",
        name: data?.name ?? "",
        father_name: data?.father_name ?? "",
        college_id: data?.college_id || "",
        assamese_name: data?.assamese_name ?? "",
        assamese_declaration: data?.assamese_declaration || false,
        stepone_declaration: data?.stepone_declaration || false,
      });
  }, [dispatch, data]);

  const handleSubmit = async (payload) => {
    setSaving(true);
    if (!data) {
      try {
        const result = await addApplication(payload).unwrap();
        if (result.status) {
          setTab(2);
          toast("Updated Successfully");
        } else {
          toast(result.message || "");
        }
      } catch (err) {
        toast(err?.data?.message || "");
        console.log("error ", err);
      } finally {
        setSaving(false);
      }
    } else {
      try {
        const result = await updateApplication({
          ...payload,
          id: data.id,
          step: 2,
        }).unwrap();
        if (result.status) {
          setTab(2);
          toast("Updated Successfully");
        } else {
        }
      } catch (err) {
        toast(err?.data?.message || "");
        console.log("error ", err);
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <>
      {isSaving && <Loader />}
      <div className="grid grid-cols-1 bg-white min-h-full items-center justify-center">
        <div className=" w-full space-y-8 shadow-2xl p-10 rounded-lg">
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              setFieldValue,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            }) => (
              <Form className="space-y-4">
                <CustomSelect
                  value={values.course_id || ""}
                  options={courses}
                  name="course_id"
                  label="Degree applied for"
                  isSearchable={true}
                  getOptionValue={(option) => `${option}`}
                  getOptionLabel={(option) => `${option.name} `}
                  onChange={(selectedOption) => {
                    console.log(selectedOption);
                    formikRef.current?.setFieldValue(
                      "course_id",
                      selectedOption.id
                    );
                  }}
                />
                <Input
                  type="month"
                  name="final_sem"
                  label="Final Semester/Year examination held in (Month/Year)"
                />
                <div className="grid grid-cols-2 gap-4">
                  <Input
                    type="date"
                    placeholder="Enter your dd/mm/yyyy"
                    name="date_of_passing"
                    label="Date of Passing"
                  />

                  <SearchInput
                    value={values.registration_no || ""}
                    name="registration_no"
                    label="Registration no. provided by Dibrugarh University"
                    placeholder="Enter Registration no. provided by Dibrugarh University"
                    onChange={(e) => {
                      setFieldValue("registration_no", e.target.value);
                      setQuery(e.target.value);
                    }}
                  />
                </div>
                <Input
                  name="name"
                  label="Name of the Candidate"
                  placeholder="Enter Name of the Candidate"
                />
                <div className="grid grid-cols-2 gap-4">
                  <AssameseInput
                    name="assamese_name"
                    label="Name of the Candidate in Assamese"
                    placeholder="Enter Name of the Candidate in Assamese"
                    disabled={values.assamese_declaration}
                    // readOnly={values.assamese_declaration}
                    // onChange={(e) => {
                    //   setFieldValue("assamese_name", e.target.value);
                    // }}
                    onFocus={() => {
                      if (!values.assamese_declaration) {
                        setAssameseKeyboardVisibility(true);
                        setCurrentInput("assamese_name");
                      } else {
                        setAssameseKeyboardVisibility(false);
                      }
                    }}
                    onBlur={() => {}}
                  />

                  <AssameseInput
                    name="re_assamese_name"
                    label="Confirm Name of the Candidate in Assamese"
                    placeholder="Re-enter Name of the Candidate in Assamese"
                    disabled={values.assamese_declaration}
                    onChange={(e) => {
                      // values.assamese_name === values.re_assamese_name
                      //   ? alert("verfied")
                      //   : alert("Not verified");
                      // setFieldValue("re_assamese_name", e.target.value);
                    }}
                    onFocus={() => {
                      if (!values.assamese_declaration) {
                        setAssameseKeyboardVisibility(true);
                        setCurrentInput("re_assamese_name");
                      } else {
                        setAssameseKeyboardVisibility(false);
                      }
                    }}
                    onBlur={() => {}}
                  />
                </div>
                {isAssameseKeyboardVisible && (
                  <AssameseKeyboard
                    // visible={isAssameseKeyboardVisible}
                    name={currentInput || ""}
                    setFieldValue={setFieldValue}
                    onClose={() => {
                      setAssameseKeyboardVisibility(false);
                    }}
                  />
                )}
                <input
                  type="checkbox"
                  id="assamese_checked"
                  name="assamese_declaration"
                  checked={values?.assamese_declaration}
                  className="rounded border-gray-300 text-green-600 shadow-sm focus:border-green-600 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                  onChange={(e) => {
                    setFieldValue("assamese_declaration", e.target.checked);
                    if (e.target.checked) {
                      setFieldValue("assamese_name", "");
                      setFieldValue("re_assamese_name", "");
                    }
                    setAssameseKeyboardVisibility(false);
                  }}
                />
                <label
                  htmlFor="assamese_checked"
                  className="ml-2 text-sm text-gray-700"
                >
                  I declare that the information provided in this form is true
                  and correct to the best of my knowledge and belief. I don't
                  know how to write my name in Assamese. Therefore, I authorize
                  the Certificate Branch, DU to convert my name to Assamese on
                  my behalf. I will have No Objection on the spelling of my name
                  in Assamese converted by the Certificate Branch, DU.
                </label>
                a
                <Input
                  name="father_name"
                  label="Father's Name"
                  placeholder="Enter Your Father's Name"
                />
                <CustomSelect
                  value={values.college_id || ""}
                  options={colleges}
                  name="college_id"
                  label="College/Department/Institute from where qualified"
                  isSearchable={true}
                  getOptionValue={(option) => `${option}`}
                  getOptionLabel={(option) => `${option.name} `}
                  onChange={(selectedOption) => {
                    console.log(selectedOption);
                    formikRef.current?.setFieldValue(
                      "college_id",
                      selectedOption.id
                    );
                  }}
                />
                <input
                  type="checkbox"
                  id="declarationCheckbox"
                  name="stepone_declaration"
                  checked={values?.stepone_declaration}
                  className="rounded border-gray-300 text-green-600 shadow-sm focus:border-green-600 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                  onChange={(e) => {
                    setFieldValue("stepone_declaration", e.target.checked);
                  }}
                />
                <label
                  htmlFor="declarationCheckbox"
                  className="ml-2 text-sm text-gray-700"
                >
                  I declare that the information provided in this form is true
                  and correct to the best of my knowledge and belief.
                </label>
                <hr />
                <div className="flex justify-end">
                  <button
                    disabled={isSaving || !values.stepone_declaration}
                    className={`${
                      values.stepone_declaration ? "bg-blue-500" : "bg-blue-300"
                    } text-white py-2 px-4 rounded`}
                    type="submit"
                    // onClick={handleSubmit}
                  >
                    {isSaving ? "Saving..." : "Next"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CertificateCreateStep1;

// const handleScreenClick = (e) => {
//   e.preventDefault();
//   if (isAssameseKeyboardVisible) {
//     setAssameseKeyboardVisibility(false);
//   }
// };
// const targetDivRef = useRef();
// useEffect(() => {
//   document.addEventListener("click", handleClickOutside);
//   return () => {
//     document.removeEventListener("click", handleClickOutside);
//   };
// }, []);
// const handleClickOutside = (event) => {
//   // console.log('targetDivRef',targetDivRef.current)
//   // console.log('event.target',event.target)
//   // if (targetDivRef.current && !targetDivRef.current.contains(event.target)) {
//   //   setAssameseKeyboardVisibility(false);
//   // }
// };
