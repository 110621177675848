import React, { useState } from "react";
import { RiEyeCloseFill, RiUploadCloud2Line } from "react-icons/ri";
import { ImEye } from "react-icons/im";
import {
  useGetApplicationQuery,
  useGetCertificateFeeQuery,
  useUpdateApplicationMutation,
} from "../../store/slices/apis/applicationApi";
import { IMAGE_BASE_URL } from "../../Utils/Common";
import {
  useGetCollegesQuery,
  useGetCoursesQuery,
  useGetDegreeModesQuery,
  useGetDocumentsQuery,
  useGetGradesQuery,
  useGetSubjectsQuery,
} from "../../store/slices/apis/masterApi";
import ImageModel from "../../Components/Common/ImageModel";
import { toast } from "react-toastify";
import Loader from "../../Components/Utils/Loader";
import { PaymentStatus } from "../../Utils/Status";

export const CertificateReviewPayment = ({
  data: application,
  certificateId,
  setTab,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const {
    data: documents = [],
    isLoading: isGetDocumentsLoading,
    isFetching: isGetDocumentsFetching,
    isSuccess: isGetDocumentsSuccess,
  } = useGetDocumentsQuery(certificateId);
  const {
    data: fee = [],
    isLoading: isGetFeeLoading,
    isFetching: isGetFeeFetching,
    isSuccess: isGetFeeSuccess,
  } = useGetCertificateFeeQuery(application?.id);

  const handleCheckBoxChange = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const allChecked = Array.from(checkboxes).every(
      (checkbox) => checkbox.checked
    );
    setIsChecked(allChecked);
  };

  const [isLoading, setLoading] = useState(false);

  const [showImageModel, setShowImageModel] = useState(null);
  const [updateApplication] = useUpdateApplicationMutation();
  const {
    data: colleges = [],
    isLoading: isLoadingGetColleges,
    isSuccess: isSuccessGetColleges,
    isError: isErrorGetColleges,
  } = useGetCollegesQuery();
  const {
    data: grades = [],
    isLoading: isLoadingGetGrades,
    isSuccess: isSuccessGetGrades,
    isError: isErrorGetGrades,
  } = useGetGradesQuery();
  const {
    data: courses = [],
    isLoading: isLoadingGetCourses,
    isSuccess: isSuccessGetCourses,
    isError: isErrorGetCourses,
  } = useGetCoursesQuery();
  const {
    data: degreeModes = [],
    isLoading: isLoadingGetDegreeModes,
    isSuccess: isSuccessGetDegreeModes,
    isError: isErrorGetDegreeModes,
  } = useGetDegreeModesQuery();
  const {
    data: subjects,
    isLoading: isLoadingGetSubjects,
    isSuccess: isSuccessGetSubjects,
    isError: isErrorGetSubjects,
  } = useGetSubjectsQuery();
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const result = await updateApplication({
        id: application.id,
        application_status: 1,
      }).unwrap();
      if (result.status) {
        toast("Saving Successful");
      } else {
        toast(result.message || "");
      }
    } catch (err) {
      toast(err?.data?.message || "");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      {showImageModel && (
        <ImageModel
          url={showImageModel}
          onClose={() => setShowImageModel(null)}
        />
      )}
      <div className="w-full bg-white space-y-8 shadow-2xl p-10">
        <div className="">
          <div className="flex flex-wrap">
            <div className="w-2/5 font-semibold space-y-2">
              <div>Name of the Candidate (English):</div>
              <div>Name of the Candidate (Assamese):</div>
            </div>
            <div className="w-3/5">
              <div className="flex">
                <div className="w-2/3 space-y-2">
                  <div>{application?.name}</div>
                  <div>{application?.assmese_name}</div>
                  <div>
                    I don't know how to write my name in Assamese. Therefore, I
                    authorize the Certificate Branch, DU to convert my name to
                    Assamese on my behalf. I will have No Objection on the
                    spelling of my name in Assamese converted by the Certificate
                    Branch, DU.
                  </div>
                </div>
                <div className="w-1/3 space-y-2">
                  {application?.applicant_photo && (
                    <img
                      className="h-44 mt-auto float-right"
                      src={`${IMAGE_BASE_URL}${application?.applicant_photo}`}
                      alt="ProfilePhoto"
                    />
                  )}
                  {application?.signature && (
                    <img
                      className="w-44 h-10 float-right"
                      src={`${IMAGE_BASE_URL}${application?.signature}`}
                      alt="Signature"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">Father's Name:</div>
            <div className="w-3/5">{application?.father_name}</div>
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">
              Registration No of Dibrugarh University:
            </div>
            <div className="w-3/5">{application?.registration_no}</div>
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">Degree applied for :</div>
            {isSuccessGetCourses && (
              <div className="w-3/5">
                {courses?.find((course) => course.id === application?.course_id)
                  ?.name || ""}
              </div>
            )}
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">
              Honours/Major/Distinction/General :
            </div>
            {isSuccessGetDegreeModes && (
              <div className="w-3/5">
                {degreeModes?.find(
                  (mode) => mode.id === application?.degree_mode_id
                )?.name || ""}
              </div>
            )}
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">Subject/Speciality :</div>
            {isSuccessGetSubjects && (
              <div className="w-3/5">
                {subjects?.find(
                  (subject) => subject.id === application?.subject_id
                )?.name || ""}
              </div>
            )}
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">Mode :</div>
            {isSuccessGetDegreeModes && (
              <div className="w-3/5">
                {degreeModes?.find(
                  (mode) => mode.id === application?.degree_mode_id
                )?.name || ""}
              </div>
            )}
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">Year of qualifying :</div>
            <div className="w-3/5">{application?.year_of_qualify}</div>
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">Roll & No :</div>
            <div className="w-3/5">{application?.roll_no}</div>
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">Class/Division/Grade :</div>
            {isSuccessGetGrades && (
              <div className="w-3/5">
                {grades?.find((grade) => grade.id === application?.grade_id)
                  ?.name || ""}
              </div>
            )}
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">Examination held in :</div>
            <div className="w-3/5">{application?.year_of_qualify}</div>
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">Date of passing :</div>
            <div className="w-3/5">{application?.date_of_passing}</div>
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">
              College/Department/Institute from where qualified :
            </div>
            {isSuccessGetColleges && (
              <div className="w-3/5">
                {colleges?.find(
                  (college) => college.id === application?.college_id
                )?.name || ""}
              </div>
            )}
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">
              Address for communication :
            </div>
            <div className="w-3/5">
              <p>
                Village/Town: {application?.postal_village}, House No./Street
                No.:
                {application?.postal_house_no}, P.O: {application?.postal_po},
                District:
                {application?.postal_district}, State: {application?.state},
                Pin:
                {application?.pincode}
              </p>
            </div>
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">Mobile No :</div>
            <div className="w-3/5">{application?.phone_number}</div>
          </div>

          <div className="flex flex-wrap text-lg space-y-2">
            <div className="w-2/5 font-semibold">Email :</div>
            <div className="w-3/5">{application?.email}</div>
          </div>

          {documents &&
            documents?.map((document) => {
              const exists = application?.documents?.find(
                (doc) => doc.document_id === document.id
              );
              return (
                <div
                  key={document.id}
                  className="flex flex-wrap text-lg space-y-2"
                >
                  <div className="w-2/5 font-semibold">
                    {document.name || ""} :
                  </div>
                  {exists?.file ? (
                    <span className="px-4 py-2 text-green-700 bg-green-100 rounded">
                      Uploaded
                    </span>
                  ) : (
                    <span className="px-4 py-2 text-gray-700 bg-gray-100 rounded">
                      Not Uploaded
                    </span>
                  )}
                  <button
                    className="text-blue-600 font-bold uppercase text-sm px-3 py-2 rounded hover:bg-blue-600 hover:text-white"
                    type="button"
                    onClick={() => {
                      setShowImageModel(exists?.file || null);
                    }}
                  >
                    {exists?.file ? "View" : "Upload"}
                    <ImEye className="inline-block ml-1" />
                  </button>
                </div>
              );
            })}
        </div>

        <hr className="border border-gray-400 border-opacity-50 my-6" />

        <div class="container mx-auto">
          <div class="p-2">
            <h2 class="font-bold text-xl">UNDERTAKING</h2>
            <p class="text-lg">I hereby declare the following,</p>

            <div class="text-lg space-y-1">
              <div class="space-x-2">
                <input
                  id="checkbox1"
                  type="checkbox"
                  onChange={handleCheckBoxChange}
                />
                <label class="font-semibold" for="checkbox1">
                  I have uploaded the correct documents during the online
                  application and ensured that the scanned images of such
                  documents are distinctly visible.
                </label>
              </div>

              <div class="space-x-2">
                <input
                  id="checkbox2"
                  type="checkbox"
                  onChange={handleCheckBoxChange}
                />
                <label class="font-semibold" for="checkbox2">
                  I have provided my own mobile No. and email id at the time of
                  online application.
                </label>
              </div>

              <div class="space-x-2">
                <input
                  id="checkbox3"
                  type="checkbox"
                  onChange={handleCheckBoxChange}
                />
                <label class="font-semibold" for="checkbox3">
                  If any of the information provided by me is found to be
                  incorrect/illegible, my application shall be liable to be
                  cancelled.
                </label>
              </div>
            </div>

            <hr class="border border-gray-400 border-opacity-50 my-6" />

            <div>
              <h2 class="font-bold text-xl">PAYMENT INFO</h2>
              <p class="text-lg">
                [You can select any one of the payment options available i.e
                Netbanking, Debit card (Visa, Master, Maestro, RuPay), credit
                card etc. The ATM-cum debit card option is for older versions of
                ATM cards which is now supported only by limited no. of banks.]
              </p>

              <div class="row text-lg space-y-2">
                <div class="col font-semibold">Application form for:</div>
                <div className="col">Original Certificate</div>
              </div>

              <div class="row text-lg space-y-2">
                <div class="col font-semibold">Application Fees:</div>
                <div className="col">{fee?.fee}</div>
              </div>
            </div>

            <hr />

            <div class="mt-3 flex justify-between">
              <button
                type="button"
                class="bg-green-500 text-white py-2 px-4 rounded"
                onClick={() => {
                  setTab(3);
                }}
              >
                Previous
              </button>

              <button
                class="bg-blue-500 text-white py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                type="submit"
                onClick={handleSubmit}
                disabled={!isChecked}
              >
                Confirm and Proceed to Pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificateReviewPayment;
