import { apiSlice } from "./apiSlice";
export const masterApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCourses: builder.query({
      query: () => ({
        url: "/course",
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Course"],
    }),
    getCertificates: builder.query({
      query: () => ({
        url: "/certificate",
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Certificate"],
    }),
    getCertificate: builder.query({
      query: () => ({
        url: "/certificate/:id",
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Certificate"],
    }),
    getColleges: builder.query({
      query: () => ({
        url: "/college",
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["College"],
    }),
    getDegreeModes: builder.query({
      query: () => ({
        url: "/degree-mode",
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["DegreeMode"],
    }),
    getDegreeModeCourses: builder.query({
      query: () => ({
        url: "/degree-mode-course",
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["DegreeModeCourse"],
    }),
    getDocuments: builder.query({
      query: (id) => ({
        url: `/document/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Document"],
    }),
    getGrades: builder.query({
      query: () => ({
        url: "/grade",
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Grade"],
    }),
    getFees: builder.query({
      query: () => ({
        url: "/fee",
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Fee"],
    }),
    getSubjects: builder.query({
      query: () => ({
        url: "/subject",
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Subject"],
    }),
    getDegreeTypes: builder.query({
      query: () => ({
        url: "/degree-type",
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["DegreeType"],
    }),
    getDegreeTypesByCourse: builder.query({
      query: (id) => ({
        url: `/degree-type/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["DegreeTypeCourse"],
    }),
  }),
});

export const {
  useGetCertificatesQuery,
  useGetCertificateQuery,
  useGetCoursesQuery,
  useGetDegreeModeCoursesQuery,
  useGetDegreeModesQuery,
  useGetDocumentsQuery,
  useGetFeesQuery,
  useGetGradesQuery,
  useGetCollegesQuery,
  useGetSubjectsQuery,
  useGetDegreeTypesQuery,
  useLazyGetDegreeTypesByCourseQuery,
} = masterApi;
