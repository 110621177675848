import React from "react";
import { useSelector } from "react-redux";

const MyAccount = () => {
  const authData = useSelector((state) => state.auth);

  return (
    <>
      <div className="max-w-md mx-auto p-4">
        <h2 className="text-2xl font-bold mb-4 text-center">
          Account Information
        </h2>
        <div className="bg-white shadow-xl rounded px-8 pt-6 pb-8 mb-4 flex flex-wrap items-center">
          <div className="w-full md:w-1/3 text-center md:pr-12 mb-4 md:mb-0">
            <img
              className=" object-cover rounded-full"
              src="../../assets/no-photo.jpg"
              alt="Profile"
            />
          </div>
          <div className="w-full md:w-2/3 md:pl-12">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <p className="text-gray-700">{authData.user.name}</p>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <p className="text-gray-700">{authData.user.email}</p>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="phone"
              >
                Phone
              </label>
              <p className="text-gray-700">{authData.user.phone}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
