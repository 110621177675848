import React from "react";
import { useGetAppliedCertificatesQuery } from "../../store/slices/apis/applicationApi";
import { getApplicationStatus } from "../../Utils/Status";
import { Link } from "react-router-dom";

export const AppliedCertificate = () => {
  const {
    data: appliedCertificates = [],
    isLoading: isLoadingGetAppliedCertificate,
    isSuccess: isSuccessGetAppliedCertificates,
    isError: isErrorGetAppliedCertificates,
  } = useGetAppliedCertificatesQuery();

  const certificates = [
    {
      id: 1,
      name: "Original Certificate",
      clourseName: "MA",
      status: "Verified",
    },
    {
      id: 2,
      name: "Pre-Degree Certificate",
      clourseName: "BA",
      status: "Applied",
    },
    {
      id: 3,
      name: "Provisional Certificate",
      clourseName: "BCA",
      status: "Ready",
    },
  ];

  const handleStatusCertificate = (certificate) => {
    // handle print certificate logic here
    console.log("Printing certificate:", certificate.name);
  };

  return (
    <>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h2 className="text-2xl font-bold mb-4">Applied Certificates</h2>
        {appliedCertificates.map((certificate) => (
          <div
            key={certificate.id}
            className="bg-white rounded-lg shadow-md mb-4"
          >
            <div className="flex items-center px-6 py-4">
              <div className="ml-4">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {certificate.certificate_name} for {certificate.course_name}
                </h3>
                <p className="text-sm font-medium leading-5 text-gray-500">
                  Status: {getApplicationStatus(certificate.status)}
                </p>
              </div>
              <Link
                to={`/dashboard`}
                className="bg-blue-500 text-white py-2 px-4 rounded ml-auto"
                onClick={() => handleStatusCertificate(certificate)}
              >
                Check Status
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
