import "./App.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NoPage from "./Pages/NoPage";
import LandingPage from "./Pages/LandingPage";
import StudentLogin from "./Pages/Student/Login";
import StudentRegister from "./Pages/Student/Register";
import StudentRoutes from "./Routes/StudentRoute";
toast.configure();
function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<LandingPage />} />
        <Route path="/register" element={<StudentRegister />} />
        <Route path="/student-login" element={<StudentLogin />} />
        {StudentRoutes}
        <Route path="*" element={<NoPage />} />
      </Routes>
    </Router>
  );
}

export default App;
