import React, { useRef, useState } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Draggable from "react-draggable";
import { GrClose } from "react-icons/gr";
const AssameseKeyboard = ({ visible = true, name, setFieldValue, onClose }) => {
  console.log("AssameseKeyboard", name);
  const [input, setInput] = useState("");
  const inputRef = useRef(null);
  const nodeRef = useRef(null);
  // const [isKeyboardVisible, setKeyboardVisibility] = useState(visible);
  const [layoutName, setLayoutName] = useState("default");
  const handleInputChange = (value) => {
    // setInput(event.target.value);
    setFieldValue(name, value);

    // console.log(name, value);
  };
  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      setLayoutName("shift");
    } else if (button === "{য়ুক্তাক্ষৰ}") {
      setLayoutName("য়ুক্তাক্ষৰ");
    } else {
      setLayoutName("default");
    }
    if (button === "{enter}") {
      setInput(input + "\n");
    }
    if (button === "{bksp}") {
      setInput(input.substring(0, input.length - 1));
    }
  };
  return (
    <div className="simple-keybaord" ref={nodeRef}>
      {/* <Draggable> */}
      {/* style={{ maxWidth: "850px" }} */}
      <div className="keyboard">
        {/* <input
            type="text"
            value={input}
            style={{ width: "100%" }}
            onChange={handleInputChange}
            placeholder="Tap on the virtual keyboard to start"
          /> */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 5,
          }}
        >
          <button>
            <GrClose
              style={{
                width: 23,
                height: 23,
              }}
              onClick={() => onClose()}
            />
          </button>
        </div>
        <Keyboard
          layoutName={layoutName}
          //  inputRef={inputRef}
          layout={{
            default: [
              "\u0965 \u09e7 \u09e8 \u09e9 \u09ea \u09eb \u09ec \u09ed \u09ee \u09ef \u09e6 \u002d \u09c3 {bksp}",
              "{tab} \u09cc \u09c8 \u09be \u09c0 \u09c2 \u09ac \u09b9 \u0997 \u09a6 \u099c \u09a1 \u09bc",
              "\u09cb \u09c7 \u09cd \u09bf \u09c1 \u09aa \u09f0 \u0995 \u09a4 \u099a \u099f {enter}",
              "{shift} \u0982 \u09ae \u09a8 \u09f1 \u09b2 \u09b8 \u002c \u002e \u09df {shift}",
              ".com @ {য়ুক্তাক্ষৰ} {space} ् ্র ্য র্য",
            ],
            shift: [
              "\u0021 \u0040 ( ) \u0983 \u098b {bksp}",
              "{tab} \u0994 \u0990 \u0986 \u0998 \u098a \u09ad \u0999 \u0998 \u09a7 \u099d \u09a2 \u099e",
              "\u0993 \u098f \u0985 \u0987 \u0989 \u09ab \u0996 \u09a5 \u099b \u099b \u09a0 {enter}",
              "{shift} \u0981 \u09a3 \u09b6 \u09b7 \u0964 \u09af {shift}",
              ".com @ {য়ুক্তাক্ষৰ} {space}",
            ],
            য়ুক্তাক্ষৰ: [
              "ক্ক ক্ষ জ্ঞ ত্ত ত্থ ন্ত ন্দ ণ্ট ণ্ঠ ণ্ড ণ্ঢ স্ত স্থ ম্ন ম্প ম্ফ ম্ব ম্ভ ম্ম",
              "{tab} স্প স্ফ স্ন স্ম হ্ণ হ্ন হ্ম স্ম প্ট প্ত প্ন প্প প্ল প্স ফ্র",
              "ক্ট ক্ত ক্ষ ক্র গ্ধ গ্ন গ্ব গ্ম গ্ল ঘ্ন ঙ্ক ঙ্গ চ্ছ জ্জ জ্ঝ জ্ঞ জ্ব জ্ম ঞ্ ঞ্ছ ঞ্জ",
              "ণ্ঢ ত্ত ত্ন ত্ব ত্ম থ্ব দ্গ দ্দ দ্ধ দ্ব দ্ভ দ্ম দ্র ধ্ব ন্ট ন্ঠ ন্ড ন্ত ন্দ",
              "ব্জ ব্দ ব্ধ ব্ব ব্ল ভ্র ন্ধ ন্ন ন্ব ন্ম ট্ট ট্ব ড্ড্ ণ্ঠ ণ্",
              ".com @ {space} ् {shift}",
            ],
          }}
          display={{
            "{enter}": "↵",
            "{bksp}": "⌫",
            "{shift}": "⇧",
            "{tab}": "⇥",
            "{lock}": "⇪",
            // স্ন: ["স", "্", "ন"],
          }}
          // onChange={(input) => setInput(input)}
          // onKeyPress={(button) => console.log(`Button pressed: ${button}`)}
          onChange={handleInputChange}
          onKeyPress={(button) => onKeyPress(button)}
          inputDisplayValue={input}
          physicalKeyboardHighlight={true}
          // inputName=""
          // theme={"hg-theme-default hg-layout-numeric numeric-theme"}
        />
      </div>
      {/* </Draggable> */}
    </div>
  );
};

export default AssameseKeyboard;
