import React, { useState } from "react";
import { Fragment } from "react";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdDashboardCustomize } from "react-icons/md";
import { BsArrowRight } from "react-icons/bs";
import { TbCertificate } from "react-icons/tb";
import { setHeaderName } from "./../../store/slices/utilSlice";
import { useDispatch } from "react-redux";
import { useGetCertificatesQuery } from "../../store/slices/apis/masterApi";
import { useGetAppliedCertificatesQuery } from "../../store/slices/apis/applicationApi";
import Stepper from "../../Components/Utils/Stepper";

export default function StudentDashboard({ currentStep }) {
  const submitStepper = () => {
    console.log("submitted");
  };

  const dispatch = useDispatch();
  const {
    data: certificates = [],
    isLoading: isLoadingGetCertificates,
    isSuccess: isSuccessGetCertificates,
    isError: isErrorGetCertificates,
  } = useGetCertificatesQuery();
  const {
    data: appliedCertificates = [],
    isLoading: isLoadingGetAppliedCertificate,
    isSuccess: isSuccessGetAppliedCertificates,
    isError: isErrorGetAppliedCertificates,
  } = useGetAppliedCertificatesQuery();

  return (
    <>
      <div className="space-y-4 relative">
        <div className="p-4 bg-gray-100 shadow-2xl gap-4 flex flex-col sm:flex-row justify-between">
          <h1 className="text-xl font-medium flex gap-2 items-center">
            <MdDashboardCustomize className="text-blue-600 mt-1" /> Dashboard
          </h1>
          <Link
            to="./applied-certificate"
            className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
          >
            View Applied Certificate
          </Link>
        </div>

        <div className="grid grid-cols-12 gap-4 shadow-2xl">
          <div className="col-span-12 sm:col-span-6">
            <div className="flex flex-col">
              <div className="mt-4 grid grid-cols-1 gap-4 mr-4 ml-4">
                {certificates &&
                  certificates?.map((certificate) => (
                    <div
                      onClick={() => {
                        dispatch(setHeaderName(certificate.name));
                      }}
                      key={certificate.id}
                      className="flex items-center rounded-xl bg-white p-4 shadow-lg hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                    >
                      <div className="flex h-12 w-12 items-center text-blue-500 text-2xl justify-center rounded-full border border-blue-100 bg-blue-50">
                        <TbCertificate />
                      </div>

                      <div className="ml-4">
                        <h2 className="font-semibold">{certificate.name}</h2>
                      </div>

                      <div className="flex mx-auto w-26 gap-2 items-center">
                        <Link
                          to={`/certificates/create/${certificate.id}`}
                          className="bg-indigo-500 hover:bg-blue-400 text-white font-semibold py-2 px-4 rounded-full transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-105 flex items-center text-sm"
                        >
                          Apply
                          <span className="ml-2">
                            <BsArrowRight />
                          </span>
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="col-span-12 sm:col-span-6">
            <div className="items-center rounded-xl bg-white p-4 shadow-lg mr-4 ml-4 mt-4">
              <div className="border-b-2 border-neutral-100 px-6 py-3 text-black">
                <h1 className="font-semibold">Applied Certificates</h1>
              </div>
              <div>
                {appliedCertificates?.map((certificate) => {
                  return (
                    <div key={certificate.id}>
                      <Stepper data={certificate} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
