import React from "react";
import { MutatingDots } from "react-loader-spinner";

const Loader = () => {
  return (
    // <div className="overlay">
    <div
    // style={{ background: "white", borderRadius: "50%" }}
    >
      <MutatingDots
        height="150"
        width="150"
        color="#1F45FC"
        secondaryColor="#1F45FC"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
    // </div>
  );
};

export default Loader;
