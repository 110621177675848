import React, { useState } from "react";
import Model from "../Components/Utils/Model";
import { useNavigate } from "react-router-dom";
const FeeStructure = ({ onContinue, onCancel }) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  let navigate = useNavigate();
  return (
    <>
      <Model
        style={{ marginTop: "30px", borderRadius: 5 }}
        className="mywidth"
        // className="max-w-full mx-auto my-8 px-4 sm:px-6 lg:px-8"
      >
        <div className="container mx-auto my-3 px-4">
          <h1 className="text-xl font-bold mb-4">Instructions</h1>
          <ol className="list-decimal list-inside mb-8 text-sm">
            <li className="mb-2">
              Digital distinctly visible copies of University Registration Card,
              Mark-sheet and Admit Card of Final Year/ Semester examination must
              be uploaded in JPG/JPEG format.
            </li>
            <li className="mb-2">
              In case of M.B.B.S. examination, digital distinctly visible copies
              of University Registration Card, Final Year Admit Card & Marksheet
              and Internship Completion Certificate must be uploaded in JPG/JPEG
              format.
            </li>
            <li className="mb-2">
              In case of (Medical) P.G. Degree, (Medical) P.G. Diploma
              Examinations and D. Pharm digital distinctly visible copies of
              Admit Card, Marksheet, University Registration Card and
              Provisional Certificate issued from the Institution last attended
              must be uploaded in JPG/JPEG format.
            </li>
            <li className="mb-2">
              FEE: For Original Certificate as shown below:
            </li>
            <div className="mb-8 overflow-x-auto">
              <table className="table-auto ">
                <thead className="border px-4 py-2 font-semibold">
                  <tr>
                    <th className="px-4 py-2">Sl. No.</th>
                    <th className="px-4 py-2">Particulars</th>
                    <th className="px-4 py-2">Amount (in Rs.)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border px-4 py-2">I</td>
                    <td className="border px-4 py-2">
                      If applied after one year from the date of passing
                    </td>
                    <td className="border px-4 py-2">800/-</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">II</td>
                    <td className="border px-4 py-2">
                      If applied after two year from the date of passing
                    </td>
                    <td className="border px-4 py-2">950/-</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">III</td>
                    <td className="border px-4 py-2">
                      If applied after three year from the date of passing
                    </td>
                    <td className="border px-4 py-2">1100/-</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">IV</td>
                    <td className="border px-4 py-2">
                      If applied after four year from the date of passing
                    </td>
                    <td className="border px-4 py-2">1250/-</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">V</td>
                    <td className="border px-4 py-2">
                      If applied after five year from the date of passing
                    </td>
                    <td className="border px-4 py-2">1400/-</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">VI</td>
                    <td className="border px-4 py-2">
                      If applied after six year from the date of passing
                    </td>
                    <td className="border px-4 py-2">1550/-</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">VII</td>
                    <td className="border px-4 py-2">
                      If applied after seven year from the date of passing
                    </td>
                    <td className="border px-4 py-2">1700/-</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">VIII</td>
                    <td className="border px-4 py-2">
                      If applied after eight year from the date of passing
                    </td>
                    <td className="border px-4 py-2">1850/-</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">IX</td>
                    <td className="border px-4 py-2">
                      If applied after nine year from the date of passing
                    </td>
                    <td className="border px-4 py-2">2000/-</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">X</td>
                    <td className="border px-4 py-2">
                      If applied after ten years or more from the date of
                      passing
                    </td>
                    <td className="border px-4 py-2">3000/-</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <li className="mb-2">
              Original Certificate may be handed over to the candidate or to
              person duly authorized by the candidate whose signature is
              attested by the candidate.
            </li>
            <li className="mb-2">
              No certificate will be issued to the Principal/ Head of the
              Department/ Institution.
            </li>
            <li className="mb-2">
              There is provision to dispatch the certificate by Registered Post
              to the candidate concerned, if the candidate so desires.
            </li>
            <li className="mb-2">
              FEE: For Original Certificate as shown below:
            </li>
          </ol>

          <label className="inline-flex items-center text-sm">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-600"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <span className="ml-2 text-gray-700">
              I have read and understood the instruction.
            </span>
          </label>

          <div className="flex flex-col sm:flex-row justify-center items-center mt-4">
            <button
              className={`w-full sm:w-auto px-4 py-2 mr-0 sm:mr-4 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 ${
                isChecked ? "" : "opacity-50 cursor-not-allowed"
              }`}
              onClick={onCancel}
              disabled={!isChecked}
            >
              Continue
            </button>
            <button
              className="w-full sm:w-auto px-4 py-2 mt-4 sm:mt-0 text-gray-700 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400"
              onClick={() => {
                navigate("/");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Model>
    </>
  );
};

export default FeeStructure;
