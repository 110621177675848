import { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLogoutMutation } from "../../store/slices/apis/authApi";
import { apiSlice } from "../../store/slices/apis/apiSlice";
import { toast } from "react-toastify";
import { RxHamburgerMenu } from "react-icons/rx";
import { ImUser } from "react-icons/im";
import Sidebar from "./Sidebar";
import { setOpenSidebar } from "../../store/slices/utilSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Header() {
  const [isLoading, setLoading] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const authData = useSelector((state) => state.auth);
  const openSidebar = useSelector((state) => state.util.openSidebar);
  const handleLogout = async () => {
    setLoading(true);
    try {
      const result = await logout().unwrap();
      if (result.status) {
        window.location.reload();
        dispatch(apiSlice.util.resetApiState());
      } else {
        toast("Something went wrong");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {openSidebar && (
        <Sidebar
          open={openSidebar}
          onClose={() => {
            dispatch(setOpenSidebar(false));
          }}
        />
      )}
      <header className="header">
        {/* w-full mx-auto px-2 sm:px-6 lg:px-8 fixed bg-gradient-to-r to-indigo-800 from-blue-500 shadow-md */}
        <div className="mx-auto px-2 sm:px-6 lg:px-8  bg-gradient-to-r to-indigo-800 from-blue-500 shadow-md">
          <div className="flex items-center justify-between h-16">
            <div className="flex-1 flex items-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                <RxHamburgerMenu
                  style={{
                    width: 25,
                    height: 25,
                    marginRight: 10,
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => dispatch(setOpenSidebar(true))}
                />
                <img
                  src="https://erp.dibru.work/images/dibru.png"
                  className="logo mr-3"
                  alt="Avatar"
                />
                <span className="text-white font-bold capitalize">
                  {authData.user.name}
                </span>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <button
                type="button"
                className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open user menu</span>
                    {/* <img
                        className="h-8 w-8 rounded-full"
                        src="https://erp.dibru.work/images/dibru.png"
                        alt=""
                      /> */}
                    <ImUser className="usericon" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/change-password"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          Change Password
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                          onClick={handleLogout}
                        >
                          Sign out
                        </Link>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
// // console.log(authData);
// if (!authData.isLoggedIn) {
//   // console.log("aaaaa");

//   window.location.pathname("/");
// }

// const logOut = useCallback(() => {
// console.log("logout call");

//   dispatch(logout())
//     .unwrap()
//     .then(() => {
//       navigate("/");
//     });
// }, [dispatch]);

// useEffect(() => {
//   EventBus.on("logout", () => {
//     logOut();
//   });

// return () => {
//     EventBus.remove("logout");
//   };
// }, [authData.user, logOut]);

// const [state, setState] = useContext(userContext)

// console.log(authData);
// if (!authData.isLoggedIn) {
//   return <Navigate to="/student-login" />;
// }
