import React, { useState } from "react";
import {
  useGetApplicationQuery,
  useUpdateApplicationMutation,
} from "../../store/slices/apis/applicationApi";
import { IMAGE_BASE_URL } from "../../Utils/Common";
import {
  useGetCollegesQuery,
  useGetCoursesQuery,
  useGetDegreeModesQuery,
  useGetGradesQuery,
  useGetSubjectsQuery,
} from "../../store/slices/apis/masterApi";
import ImageModel from "../../Components/Common/ImageModel";
import { toast } from "react-toastify";
import Loader from "../../Components/Utils/Loader";
import { PaymentStatus } from "../../Utils/Status";

export const ApplicationDetail = ({ data: application }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckBoxChange = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const allChecked = Array.from(checkboxes).every(
      (checkbox) => checkbox.checked
    );
    setIsChecked(allChecked);
  };

  const [isLoading, setLoading] = useState(false);

  const [showImageModel, setShowImageModel] = useState(null);
  const [updateApplication] = useUpdateApplicationMutation();
  const {
    data: colleges = [],
    isLoading: isLoadingGetColleges,
    isSuccess: isSuccessGetColleges,
    isError: isErrorGetColleges,
  } = useGetCollegesQuery();
  const {
    data: grades = [],
    isLoading: isLoadingGetGrades,
    isSuccess: isSuccessGetGrades,
    isError: isErrorGetGrades,
  } = useGetGradesQuery();
  const {
    data: courses = [],
    isLoading: isLoadingGetCourses,
    isSuccess: isSuccessGetCourses,
    isError: isErrorGetCourses,
  } = useGetCoursesQuery();
  const {
    data: degreeModes = [],
    isLoading: isLoadingGetDegreeModes,
    isSuccess: isSuccessGetDegreeModes,
    isError: isErrorGetDegreeModes,
  } = useGetDegreeModesQuery();
  const {
    data: subjects,
    isLoading: isLoadingGetSubjects,
    isSuccess: isSuccessGetSubjects,
    isError: isErrorGetSubjects,
  } = useGetSubjectsQuery();
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const result = await updateApplication({
        id: application.id,
        application_status: 1,
      }).unwrap();
      if (result.status) {
        toast("Saving Successful");
      } else {
        toast(result.message || "");
      }
    } catch (err) {
      toast(err?.data?.message || "");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="text-center">
        {/* {application?.payment_status === PaymentStatus.PAID && ( )} */}

        <img
          src="../../assets/DU.png"
          alt="university_logo"
          className="w-3/6 m-auto"
        />
        <h2 className="font-bold text-2xl">
          Application form for Original Certificate
        </h2>
      </div>

      {showImageModel && (
        <ImageModel
          url={showImageModel}
          onClose={() => setShowImageModel(null)}
        />
      )}
      <div className="w-full bg-white space-y-8 shadow-2xl p-10">
        <div>
          <div className="">
            <div className="row text-lg">
              <div className="col-5 font-semibold space-y-2">
                <div>Name of the Candidate (English) :</div>
                <div> Name of the Candidate (Assamese) :</div>
              </div>
              <div className="col-7">
                <div className="row">
                  <div className="col-8 space-y-2">
                    <div>{application?.name}</div>
                    <div>{application?.assmese_name}</div>
                    <div>
                      I don't know how to write my name in Assamese. Therefore,
                      I authorize the Certificate Branch, DU to convert my name
                      to Assamese on my behalf. I will have No Objection on the
                      spelling of my name in Assamese converted by the
                      Certificate Branch, DU.
                    </div>
                  </div>

                  <div className="col-4 space-y-2">
                    {application?.applicant_photo && (
                      <img
                        className="h-44 mt-auto float-right"
                        src={`${IMAGE_BASE_URL}${application?.applicant_photo}`}
                        alt="ProfilePhoto"
                      />
                    )}
                    {application?.signature && (
                      <img
                        className="w-44 h-10 float-right"
                        src={`${IMAGE_BASE_URL}${application?.signature}`}
                        alt="Signature"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">Father's Name :</div>
              <div className="col-7">{application?.father_name}</div>
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">
                Registration No of Dibrugarh University :
              </div>
              <div className="col-7">{application?.registration_no}</div>
            </div>
            <div className="row text-lg space-y-2 ">
              <div className="col-5 font-semibold">Degree applied for :</div>
              {isSuccessGetCourses && (
                <div className="col-7">
                  {courses?.find(
                    (course) => course.id === application?.course_id
                  )?.name || ""}
                </div>
              )}
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">
                Honours/Major/Distinction/General :
              </div>
              {isSuccessGetDegreeModes && (
                <div className="col-7">
                  {degreeModes?.find(
                    (mode) => mode.id === application?.degree_mode_id
                  )?.name || ""}
                </div>
              )}
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">Subject/Speciality :</div>

              {isSuccessGetSubjects && (
                <div className="col-7">
                  {subjects?.find(
                    (subject) => subject.id === application?.subject_id
                  )?.name || ""}
                </div>
              )}
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">Mode :</div>
              {isSuccessGetDegreeModes && (
                <div className="col-7">
                  {degreeModes?.find(
                    (mode) => mode.id === application?.degree_mode_id
                  )?.name || ""}
                </div>
              )}
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">Year of qualifying :</div>
              <div className="col-7">{application?.year_of_qualify}</div>
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">Roll & No :</div>
              <div className="col-7">{application?.roll_no}</div>
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">Class/Division/Grade :</div>
              {isSuccessGetGrades && (
                <div className="col-7">
                  {grades?.find((grade) => grade.id === application?.grade_id)
                    ?.name || ""}
                </div>
              )}
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">Examination held in :</div>
              <div className="col-7">{application?.year_of_qualify}</div>
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">Date of passing :</div>
              <div className="col-7">{application?.date_of_passing}</div>
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">
                College/Department/Institute from where qualified :
              </div>
              {isSuccessGetColleges && (
                <div className="col-7">
                  {colleges?.find(
                    (college) => college.id === application?.college_id
                  )?.name || ""}
                </div>
              )}
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">
                Address for communication :
              </div>
              <div className="col-7">
                Village/Town: {application?.postal_village},House No./Street
                No.: {application?.postal_house_no},P.O:
                {application?.postal_po}, District:{" "}
                {application?.postal_district}, State: {application?.state},
                Pin:
                {application?.pincode}
              </div>
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">Mobile No :</div>
              <div className="col-7">{application?.phone_number}</div>
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">Email :</div>
              <div className="col-7">{application?.email}</div>
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">Admit Card :</div>
              <div className="col-7">
                <button
                  className="font-bold uppercase text-sm px-6 py-3 rounded shadow mr-1 mb-1"
                  type="button"
                  onClick={() => {
                    setShowImageModel(application?.admit_card || null);
                  }}
                >
                  Uploaded
                </button>
              </div>
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">
                Registration Card/Migration Certificate :
              </div>
              <div className="col-7">
                <button
                  className="font-bold uppercase text-sm px-6 py-3 rounded shadow mr-1 mb-1"
                  type="button"
                  onClick={() => {
                    setShowImageModel(application?.registration_card || null);
                  }}
                >
                  Uploaded
                </button>
              </div>
            </div>

            <div className="row text-lg space-y-2">
              <div className="col-5 font-semibold">Marksheet/Gradesheet :</div>
              <div className="col-7">
                <button
                  className="font-bold uppercase text-sm px-6 py-3 rounded shadow mr-1 mb-1"
                  type="button"
                  onClick={() => {
                    setShowImageModel(application?.marksheet || null);
                  }}
                >
                  Uploaded
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr className="border border-gray-600 border-opacity-50 my-6" />

        <h2 className="font-bold text-xl">UNDERTAKING</h2>
        <p className="text-lg">I hereby declare the following,</p>

        <div className="text-lg space-y-1">
          <div className="space-x-2">
            <input
              id="checkbox1"
              type="checkbox"
              onChange={handleCheckBoxChange}
            />
            <label className="font-semibold" htmlFor="checkbox1">
              I have uploaded the correct documents during the online
              application and ensured that the scanned images of such documents
              are distinctly visible.
            </label>
          </div>

          <div className="space-x-2">
            <input
              id="checkbox2"
              type="checkbox"
              onChange={handleCheckBoxChange}
            />
            <label className="font-semibold" htmlFor="checkbox2">
              I have provided my own mobile No. and email id at the time of
              online application.
            </label>
          </div>

          <div className="space-x-2">
            <input
              id="checkbox3"
              type="checkbox"
              onChange={handleCheckBoxChange}
            />
            <label className="font-semibold" htmlFor="checkbox3">
              If any of the information provided by me is found to be
              incorrect/illegible, my application shall be liable to be
              cancelled.
            </label>
          </div>
        </div>

        <hr className="border border-gray-600 border-opacity-50 my-6" />
        <div>
          <h2 className="font-bold text-xl">PAYMENT INFO</h2>
          <p className="text-lg ">
            [You can select any one of the payment options available i.e
            Netbanking, Debit card (Visa, Master, Maestro, RuPay), credit card
            etc. The ATM-cum debit card option is for older versions of ATM
            cards which is now supported only by limited no. of banks.]
          </p>

          <div className="row text-lg space-y-2">
            <div className="col font-semibold">Application form for :</div>
            <div className="col">Original Certificate</div>
          </div>
          <div className="row text-lg space-y-2">
            <div className="col font-semibold">Application Fees :</div>
            <div className="col">950.00</div>
          </div>
        </div>

        <hr />

        <div className="flex justify-between">
          <button
            type="button"
            className="bg-green-500 text-white py-2 px-4 rounded"
          >
            Previous
          </button>

          <button
            className={`bg-blue-500 text-white py-2 px-4 rounded ${
              isChecked ? "" : "opacity-50 cursor-not-allowed"
            }`}
            type="submit"
            onClick={handleSubmit}
            disabled={!isChecked}
          >
            Confirm and Proceed to Pay
          </button>
        </div>
        {/* {application?.payment_status === PaymentStatus.PAID && ( */}
        <div className="flex justify-center gap-5">
          <button
            type="button"
            className="bg-green-500 text-white py-2 px-4 rounded"
          >
            Print Money Receipt
          </button>

          <button
            className="bg-blue-500 text-white py-2 px-4 rounded "
            type="submit"
            onClick={handleSubmit}
          >
            Print Application
          </button>
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default ApplicationDetail;
