import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useState,useEffect } from 'react/cjs/react.development';
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSigninMutation } from "../../store/slices/apis/authApi";
import { setAuthenticated, setUser } from "../../store/slices/authSlice";
import Input from "../../Components/Utils/Input";
import { toast } from "react-toastify";
// import messageSlice from "../../../Redux/MessageSlice";
// import messageSlice from "../../../Redux/MessageSlice";

export default function StudentLogin() {
  const [loading, setLoading] = useState(false);
  // const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const [signin] = useSigninMutation();
  // const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(message);
  // useEffect(() => {
  //   dispatch(messageSlice.actions.clearMessage());
  //   if (isLoggedIn) {
  //     console.log("sd", isLoggedIn);
  //     navigate("/dashboard");
  //     window.location.reload();
  //   }
  // }, []);
  const initialValues = {
    username: "",
    password: "",
    // panel: "student",
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      // .email("Please enter a valid email")
      .required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const handleLogin = async (formValue, { resetForm }) => {
    setLoading(true);
    try {
      const result = await signin(formValue).unwrap();
      if (result.status) {
        toast("Login Successful");
        dispatch(setAuthenticated(true));
        dispatch(setUser(result.user || {}));
        navigate("/dashboard");
        // window.location.reload();
      } else {
        toast(result.message);
      }
    } catch (err) {
      toast(err?.data?.message ?? "");
      console.log("error ", err);
    } finally {
      // resetForm();
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate("/dashboard", { replace: true });
  //   }
  // }, [isLoggedIn, navigate]);

  return (
    <>
      <div className="min-h-full flex items-center justify-center px-4 sm:px-6 lg:px-8 mt-10">
        <div className="max-w-md w-full space-y-8 shadow-2xl p-10 rounded-lg">
          <div>
            <img
              className="mx-auto h-12 w-auto rounded-full"
              src="https://erp.dibru.work/images/dibru.png"
              alt="Logo"
            />

            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-600">
              Dibrugarh University Certificate
            </h2>
            <h2 className="mt-1 text-center text-xl font-medium text-gray-600">
              Login to continue...
            </h2>
          </div>
          {/* {message && <Alert message={message} />} */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            <Form>
              <input type="hidden" name="remember" defaultValue="true" />
              <Input
                name="username"
                type="text"
                placeholder="Enter your registered email/contact number"
              />

              <Input
                name="password"
                type="password"
                placeholder="Enter your password"
              />

              <div className="flex items-center justify-between my-10">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <Link
                    to="change-password"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                </button>

                <div className="mt-5">
                  <span>No account? </span>
                  <Link to="/register" className="text-indigo-600">
                    Create New Account
                  </Link>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
