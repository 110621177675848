import React, { useState } from "react";
import styles from "./../../styles/Stepper.module.css";
import { HiCheckCircle } from "react-icons/hi";
import { FiCircle } from "react-icons/fi";
import { ApplicationStatus } from "../../Utils/Status";
const Stepper = ({ data, status }) => {
  // console.log("Stepper status", data.status);
  const activeStyle = { width: 40, height: 40, color: "#6366F1" };
  const inActiveStyle = { width: 40, height: 40, color: "#cccccc" };
  return (
    <div>
      <h3>{data?.certificate_name || ""}</h3>
      <div className={styles.container}>
        <div className="row" style={{ background: "" }}>
          <div className="">
            <span className={`${styles.circle}`}>
              {data.status >= ApplicationStatus.DRAFT ? (
                <HiCheckCircle style={{ ...activeStyle }} />
              ) : (
                <FiCircle style={{ ...inActiveStyle }} />
              )}
            </span>
            <div>
              <span
                //  className={`${styles.indicator}`}
                style={{
                  height: 60,
                  width: 3,
                  background: "#6366F1",
                  borderRadius: 10,
                  margin: "auto",
                  display: "table",
                }}
              />
            </div>
          </div>
          <div className="ml-2">
            <h5>Applied</h5>
            <h5>{data?.applied_at}</h5>
          </div>
        </div>
        <div className="row" style={{ background: "" }}>
          <div>
            <span className={`${styles.circle}`}>
              {data.status >= ApplicationStatus.VERIFIED ? (
                <HiCheckCircle style={{ ...activeStyle }} />
              ) : (
                <FiCircle style={{ ...inActiveStyle }} />
              )}
            </span>
            <div style={{}}>
              <span
                className={`${styles.indicator}`}
                style={{
                  height: 60,
                  width: 3,
                  background: "#6366F1",
                  borderRadius: 10,
                  margin: "auto",
                  display: "table",
                }}
              />
            </div>
          </div>
          <div className="ml-2">
            <h5>Verified</h5>
            <h5></h5>
          </div>
        </div>
        <div className="row" style={{ background: "" }}>
          <div>
            <span className={`${styles.circle}`}>
              {data.status >= ApplicationStatus.ISSUED ? (
                <HiCheckCircle style={{ ...activeStyle }} />
              ) : (
                <FiCircle style={{ ...inActiveStyle }} />
              )}
            </span>
            <div style={{}}>
              <span
                className={styles.indicator}
                style={{
                  height: 60,
                  width: 3,
                  background: "#6366F1",
                  borderRadius: 10,
                  margin: "auto",
                  display: "table",
                }}
              />
            </div>
          </div>
          <div className="ml-2">
            <h5>Issued</h5>
            <h5></h5>
          </div>
        </div>
        <div className="row" style={{ background: "" }}>
          <div>
            <span className={`${styles.circle}`}>
              {data.status >= ApplicationStatus.READY ? (
                <HiCheckCircle style={{ ...activeStyle }} />
              ) : (
                <FiCircle style={{ ...inActiveStyle }} />
              )}
            </span>
            <div style={{}}>
              <span
                // className={styles.indicator}
                style={{
                  height: 60,
                  width: 3,
                  background: "#6366F1",
                  borderRadius: 10,
                  margin: "auto",
                  display: "table",
                }}
              />
            </div>
          </div>
          <div className="ml-2">
            <h5>Ready</h5>
            <h5></h5>
          </div>
        </div>
        <div className="row" style={{ background: "" }}>
          <div>
            <span className={`${styles.circle}`}>
              {data.status >= ApplicationStatus.DELIVERED ? (
                <HiCheckCircle style={{ ...activeStyle }} />
              ) : (
                <FiCircle style={{ ...inActiveStyle }} />
              )}
            </span>
          </div>
          <div className="ml-2">
            <h5>Delivered</h5>
            <h5></h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
