import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "cropperjs/dist/cropper.min.css";
import Upload from "../../Components/Utils/Upload";
import CropImage from "../../Components/Utils/CropImage";
import {
  useUploadApplicationFileMutation,
  useUploadApplicationDocFileMutation,
  useUpdateApplicationMutation,
} from "./../../store/slices/apis/applicationApi";
import { useGetDocumentsQuery } from "./../../store/slices/apis/masterApi";
import { toast } from "react-toastify";
import { base64StringtoFile, fileToUrl } from "../../Utils/Status";
import { IMAGE_BASE_URL } from "../../Utils/Common";
import Loader from "../../Components/Utils/Loader";

export const CertificateDocUpload = ({
  application,
  certificateId,
  setTab,
}) => {
  const {
    data: documents = [],
    isLoading: isGetDocumentsLoading,
    isFetching: isGetDocumentsFetching,
    isSuccess: isGetDocumentsSuccess,
  } = useGetDocumentsQuery(certificateId);
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isOpenApplicantCropper, setOpenApplicantCropper] = useState(false);
  const [isOpenSignatureCropper, setOpenSignatureCropper] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadApplicationFile] = useUploadApplicationFileMutation();
  const [uploadApplicationDocFile] = useUploadApplicationDocFileMutation();
  const [uploadApplication] = useUpdateApplicationMutation();
  const handleUploadDocFile = async (document_id, file) => {
    if (file) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("id", application.id);
        formData.append("document_id", document_id);
        formData.append("file", file);
        const result = await uploadApplicationDocFile(formData).unwrap();
        if (result.status) {
          toast("Saving Successful");
          setTab(4);
        } else {
          toast(result.message || "");
        }
      } catch (err) {
        toast(err?.data?.message || "");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUploadFile = async (name, file) => {
    if (file) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("id", application.id);
        formData.append("file", file);
        formData.append("name", name);
        const result = await uploadApplicationFile(formData).unwrap();
        if (result.status) {
          toast("Saving Successful");
        } else {
          toast(result.message || "");
        }
      } catch (err) {
        toast(err?.data?.message || "");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const result = await uploadApplication({
        id: application.id,
        step: 4,
      }).unwrap();
      if (result.status) {
        toast("Saving Successful");
      } else {
        toast(result.message || "");
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isSaving && <Loader />}
      <div className="bg-white min-h-full items-center justify-center">
        <div className=" w-full shadow-2xl p-10 rounded-lg">
          {/* APPLICANT IMAGE SECTION START */}
          <div>
            <label
              htmlFor="file"
              className="text-md font-medium text-gray-700 mb-1"
            >
              Upload Applicant's Photo
            </label>
            <div className="flex justify-between">
              <Upload
                id="file"
                name="applicant_photo"
                type="file"
                onChange={(event) => {
                  setFile(event.currentTarget.files[0]);
                  setOpenApplicantCropper(true);
                }}
              />
              {isOpenApplicantCropper && file && (
                <CropImage
                  // initialAspectRatio={16 / 9}
                  // aspectRatio={150 / 180}
                  aspectRatio={150 / 180}
                  name="applicant_photo"
                  image={file}
                  isOpen={isOpenApplicantCropper}
                  onClose={() => {
                    setOpenApplicantCropper(false);
                    setFile(null);
                  }}
                  onUpload={(name, file) => {
                    handleUploadFile(name, file);
                  }}
                />
              )}
              <div>
                {application?.applicant_photo && (
                  <img
                    src={`${IMAGE_BASE_URL}${application.applicant_photo}`}
                    alt="Applicant"
                    className="mt-1 max-h-36 object-contain"
                  />
                )}
              </div>
            </div>
          </div>
          {/* APPLICANT IMAGE SECTION END */}

          {/* SIGNATURE SECTION START */}
          <div>
            <label
              htmlFor="signature"
              className="text-md font-medium text-gray-700 mb-1"
            >
              Upload Applicant's Signature
            </label>
            <div className="flex justify-between">
              <Upload
                id="signature"
                name="signature"
                type="file"
                onChange={(event) => {
                  setFile(event.currentTarget.files[0]);
                  setOpenSignatureCropper(true);
                }}
              />
              {isOpenSignatureCropper && file && (
                <CropImage
                  // initialAspectRatio={16 / 9}
                  aspectRatio={100 / 25}
                  name="signature"
                  image={file}
                  isOpen={isOpenSignatureCropper}
                  onClose={() => {
                    setOpenSignatureCropper(false);
                    setFile(null);
                  }}
                  onUpload={(name, file) => {
                    handleUploadFile(name, file);
                  }}
                />
              )}
              <div>
                {application?.signature && (
                  <img
                    src={`${IMAGE_BASE_URL}${application.signature}`}
                    alt="Signature"
                    className="mt-1 max-h-36 object-contain"
                  />
                )}
              </div>
            </div>
          </div>
          {/* SIGNATURE SECTION END */}

          {documents.map((document) => {
            const exists = application?.documents?.find(
              (doc) => doc.document_id === document.id
            );

            return (
              <div key={document.id}>
                <label
                  htmlFor={`file-${document.id}`}
                  className="text-md font-medium text-gray-700 mb-1"
                >
                  Upload {document.name}
                </label>
                <div className="flex justify-between">
                  <Upload
                    id="file"
                    name={`file-${document.id}`}
                    type="file"
                    // onChange={(event) => {
                    //   setFieldValue(
                    //     document.id,
                    //     event.currentTarget.files[0]
                    //   );
                    // }}
                    onChange={(event) =>
                      handleUploadDocFile(
                        document.id,
                        event.currentTarget.files[0]
                      )
                    }
                  />
                  {/* <ErrorMessage
                        name={document.id}
                        component="div"
                        className="text-red-500"
                      /> */}

                  <div>
                    {exists && (
                      <img
                        src={`${IMAGE_BASE_URL}${exists.file}`}
                        alt={document.name}
                        className="mt-1 max-h-36 object-contain"
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}

          <hr />

          <div className="flex justify-between mt-3">
            <button
              type="button"
              className="bg-green-500 text-white py-2 px-4 rounded"
              onClick={() => {
                setTab(2);
              }}
            >
              Preview
            </button>
            <button
              type="button"
              className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
              disabled={false}
              onClick={handleSubmit}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CertificateDocUpload;
