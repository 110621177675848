import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import PageTitle from "../../Components/Common/PageTitle";
import Input from "../../Components/Utils/Input";

export default function StudentChangePassword() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // const { message } = useSelector((state) => state.message);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confNewPassword: "",
  };
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("This field is required!"),
    newPassword: Yup.string().required("This field is required!"),
    confNewPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword"), null],
        "New Password and Confirm Password Must Match"
      )
      .required("This field is required!"),
  });
  return (
    <>
      <div className="py-2">
        <div className="mx-auto py-2 container bg-white shadow-2xl rounded-xl">
          <div className="w-full overflow-x-scroll xl:overflow-x-hidden">
            <PageTitle name="Change Password" />
            {/* {message && <Alert message={message} />} */}

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              <Form>
                <div className=" p-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8">
                  <Input
                    type="password"
                    label="Old Password"
                    id="oldPassword"
                    name="oldPassword"
                  />
                  <Input
                    type="password"
                    label="New Password"
                    id="newPassword"
                    name="newPassword"
                  />
                  <Input
                    type="password"
                    label="Enter New Password Again"
                    id="confNewPassword"
                    name="confNewPassword"
                  />
                </div>

                <div className="flex items-center justify-between my-5"></div>

                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="group relative w-100  py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={loading}
                  >
                    {loading && <span>Saving...</span>}
                    {!loading && <span>Submit</span>}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
