import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Input from "../../Components/Utils/Input";
import CustomSelect from "../../Components/Utils/CustomSelect";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  useGetGradesQuery,
  useGetDegreeModesQuery,
  useGetSubjectsQuery,
  useGetDegreeTypesQuery,
  useLazyGetDegreeTypesByCourseQuery,
} from "./../../store/slices/apis/masterApi";
import { useUpdateApplicationMutation } from "./../../store/slices/apis/applicationApi";
import { toast } from "react-toastify";
import Loader from "../../Components/Utils/Loader";

// const {
//   data: subjects,
//   isLoading: isLoadingGetSubject,
//   isSuccess: isSuccessGetSubject,
//   isError: isErrorGetSubject,
// } = useGetSubjectsQuery();

const radioCheck = [
  {
    label:
      "I shall collect my certificate from Certificate Branch, Dibrugarh University.",
    value: 1,
  },
  {
    label:
      "I want to receive my certificate in the address fields provided above.",
    value: 2,
  },
  {
    label: "I want to receive my certificate in different postal address.",
    value: 3,
  },
];

const validationSchema = Yup.object().shape({
  degree_mode_id: Yup.number().required("Please select an Degree Mode"),
  year_of_qualify: Yup.number().required("Year is required"),
  // selectedRollNo: Yup.string().required("Please select Roll & No."),
  grade_id: Yup.number().required("Please select a grade"),
  village: Yup.string().required("Please select village"),
  house_no: Yup.string().required("Please select House No./Street No."),
  postoffice: Yup.string().required("Please select Post Office"),
  district: Yup.string().required("Please select District"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string().required("Please select Pin"),
  phone_number: Yup.string()
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Invalid mobile number")
    .required("Mobile number is required"),
  email: Yup.string().email("Invalid email address").required("Required"),
});

export const CertificateCreateStep2 = ({ data, setTab }) => {
  console.log("CertificateCreateStep2", data);
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  // const [applicationId, setApplicationId] = useState(0);
  const [updateApplication] = useUpdateApplicationMutation();
  const [initialValues, setInitialValues] = useState({
    degree_mode_id: 0,
    degree_type_id: 0,
    grade_id: 0,
    subject_id: "",
    year_of_qualify: "",
    division: "",
    village: "",
    houseNo: "",
    postoffice: "",
    district: "",
    state: "",
    pincode: "",
    phone_numbeer: "",
    email: "",
    postal_village: "",
    postal_po: "",
    postal_house_no: "",
    postal_district: "",
    postal_state: "",
    postal_pin: "",
    certificate_delivery_type: 0,
  });
  const {
    data: subjects = [],
    isLoading: isLoadingGetSubjects,
    isSuccess: isSuccessGetSubjects,
    isError: isErrorGetSubjects,
  } = useGetSubjectsQuery();
  const {
    data: grades = [],
    isLoading: isLoadingGetGrades,
    isSuccess: isSuccessGetGrades,
    isError: isErrorGetGrades,
  } = useGetGradesQuery();
  const {
    data: degreeModes = [],
    isLoading: isLoadingGetDegreeModes,
    isSuccess: isSuccessGetDegreeModes,
    isError: isErrorGetDegreeModes,
  } = useGetDegreeModesQuery();
  // const {
  //   data: degreeTypes = [],
  //   isLoading: isLoadingGetDegreeTypes,
  //   isSuccess: isSuccessGetDegreeTypes,
  //   isError: isErrorGetDegreeTypes,
  // } = useGetDegreeTypesQuery();
  const [
    getDegreeTypesByCourse,
    {
      data: degreeTypes = [],
      isFetching: isFetchingGetDegreeTypes,
      isLoading: isLoadingGetDegreeTypes,
      isSuccess: isSuccessGetDegreeTypes,
      isError: isErrorGetDegreeTypes,
    },
  ] = useLazyGetDegreeTypesByCourseQuery();
  const formikRef = useRef();
  const [selectedRollNo, setSelectedRollNo] = useState("");
  const [certificateDeliveryType, setCertificateDeliveryType] = useState(0);
  const handleRollNoChange = (selectedOption) => {
    setSelectedRollNo(selectedOption.id);
  };

  const [showAddressFields, setShowAddressFields] = useState(false);

  const handleCertificateDeliveryTypeChange = (event) => {
    setCertificateDeliveryType(event.target.value);
  };
  const handleSubmit = async (payload) => {
    try {
      setSaving(true);
      const result = await updateApplication({
        id: data.id,
        step: 3,
        ...payload,
      }).unwrap();
      if (result.status) {
        toast("Saving Successful");
        setTab(3);
      } else {
        toast(result.message || "");
      }
    } catch (err) {
      toast(err?.data?.message || "");
      console.log("error ", err);
    } finally {
      setSaving(false);
    }
  };
  useEffect(() => {
    if (data) {
      getDegreeTypesByCourse(data?.course_id);
      setInitialValues({
        course_id: data?.course_id || 0,
        degree_type_id: data?.degree_type_id || 0,
        roll_no_type: data?.roll_no_type || 0,
        exam_roll_no: data?.exam_roll_no || "",
        exam_roll: data?.exam_roll || "",
        exam_no: data?.exam_no || "",
        final_sem: data?.final_sem || "",
        date_of_passing: data?.date_of_passing || "",
        name: data?.name || "",
        father_name: data?.father_name ?? "",
        college_id: data?.college_id || 0,
        assamese_name: data?.assamese_name || "",
        year_of_qualify: data?.year_of_qualify || "",
        degree_mode_id: data?.degree_mode_id || 0,
        subject_id: data?.subject_id || "",
        grade_id: data?.grade_id || 0,
        village: data?.village || "",
        house_no: data?.house_no || "",
        postoffice: data?.postoffice || "",
        district: data?.district || "",
        state: data?.state || "",
        pincode: data?.pincode || "",
        phone_number: data?.phone_number || "",
        email: data?.email || "",
        postal_village: data?.postal_village || "",
        postal_po: data?.postal_po || "",
        postal_house_no: data?.postal_house_no || "",
        postal_district: data?.postal_district || "",
        postal_state: data?.postal_state || "",
        postal_pincode: data?.postal_pincode || "",
        step: 3,
        certificate_delivery_type: data?.certificate_delivery_type || 0,
      });
    }
  }, [data]);
  return (
    <>
      {isSaving && <Loader />}
      <div className="grid grid-cols-1 bg-white min-h-full items-center justify-center">
        <div className=" w-full space-y-8 shadow-2xl p-10 rounded-lg">
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            }) => (
              <Form className="space-y-4">
                {/* {console.log("errors", errors)} */}
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-sm font-medium text-gray-700 mb-1">
                      Major/General
                    </label>
                    {degreeTypes.map((degreeType) => (
                      <label className="flex items-center" key={degreeType.id}>
                        <Field
                          type="radio"
                          name="degree_type_id"
                          value={degreeType.id}
                          className="form-radio"
                          checked={values.degree_type_id == degreeType.id}
                        />
                        <span className="ml-2 ">{degreeType.name}</span>
                      </label>
                    ))}
                  </div>

                  <CustomSelect
                    value={values.subject_id || ""}
                    options={subjects}
                    name="subject_id"
                    label="Subject/Speciality"
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "subject_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <div>
                    <label className="text-sm font-medium text-gray-700 mb-1">
                      Mode
                    </label>
                    {degreeModes.map((option) => (
                      <label className="flex items-center" key={option.id}>
                        <Field
                          type="radio"
                          name="degree_mode_id"
                          value={option.id}
                          className="form-radio"
                          checked={values.degree_mode_id == option.id}
                        />
                        <span className="ml-2">{option.name}</span>
                      </label>
                    ))}
                  </div>
                  <Input
                    name="year_of_qualify"
                    label="Year of qualifying"
                    placeholder="YYYY"
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <CustomSelect
                    options={[
                      { id: 1, name: "Single Roll & No." },
                      { id: 2, name: "Separate Roll & No" },
                    ]}
                    name="roll_no_type"
                    label="Roll & No"
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    value={values.roll_no_type || 0}
                    onChange={(selectedOption) => {
                      formikRef.current?.setFieldValue(
                        "roll_no_type",
                        selectedOption.id
                      );
                      handleRollNoChange(selectedOption);
                    }}
                  />
                  <div className="gap-4">
                    {values.roll_no_type === 1 && (
                      <Input
                        name="exam_roll_no"
                        label="Enter Roll No."
                        placeholder="Enter Single Roll No."
                        type="text"
                      />
                    )}
                    <div className="flex justify-start gap-4">
                      {values.roll_no_type === 2 && (
                        <>
                          <Input
                            name="exam_roll"
                            label="Enter Roll"
                            placeholder="Enter Roll"
                            type="text"
                          />
                          <Input
                            name="exam_no"
                            label="Enter No."
                            placeholder="Enter No"
                            type="text"
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <CustomSelect
                  name="grade_id"
                  options={grades}
                  label="Class/Division/Grade"
                  getOptionValue={(option) => `${option}`}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(selectedOption) => {
                    console.log(selectedOption);
                    formikRef.current?.setFieldValue(
                      "grade_id",
                      selectedOption.id
                    );
                  }}
                  value={values.grade_id || 0}
                />
                <br />
                <label className="text-sm font-semibold text-gray-700 mb-1">
                  ADDRESS
                </label>
                <div className="grid grid-cols-2 gap-4">
                  <Input
                    name="village"
                    type="text"
                    label="Village / Town"
                    placeholder="Enter Village / Town"
                  />
                  <Input
                    name="house_no"
                    type="text"
                    label="House No./Street No."
                    placeholder="Enter Your House No./Street No."
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <Input
                    name="postoffice"
                    type="text"
                    label="P.O."
                    placeholder="Enter Post Office"
                  />

                  <Input
                    type="text"
                    name="district"
                    label="District"
                    placeholder="Enter District"
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <Input
                    type="text"
                    name="state"
                    label="State"
                    placeholder="Enter Your State"
                  />

                  <Input
                    type="text"
                    name="pincode"
                    placeholder="Enter Your Pin"
                    label="Pin"
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <Input
                    type="text"
                    name="phone_number"
                    label="Mobile No"
                    placeholder="Enter Your Mobile No"
                  />
                  <Input
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="example@example.com"
                  />
                </div>
                <br />
                <div>
                  {radioCheck.map((option) => {
                    return (
                      <label className="flex items-center" key={option.value}>
                        <Field
                          type="radio"
                          name="certificate_delivery_type"
                          value={option.value}
                          checked={
                            values.certificate_delivery_type === option.value
                          }
                          onChange={() => {
                            formikRef.current?.setFieldValue(
                              "certificate_delivery_type",
                              option.value
                            );
                            formikRef.current?.setFieldValue(
                              "terms_declaration",
                              true
                            );
                          }}
                          className="form-radio"
                        />
                        <span className="ml-2">{option.label}</span>
                      </label>
                    );
                  })}
                  <ErrorMessage
                    name="certificate_delivery_type"
                    component="div"
                    className="text-red-500 mt-1"
                  />
                </div>

                {values.certificate_delivery_type === 3 && (
                  <div>
                    <div className="grid grid-cols-2 gap-4">
                      <Input
                        name="postal_village"
                        type="text"
                        label="Village / Town"
                        placeholder="Enter Village / Town"
                      />

                      <Input
                        name="postal_house_no"
                        type="text"
                        label="House No./Street No."
                        placeholder="Enter Your House No./Street No."
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <Input
                        name="postal_po"
                        type="text"
                        label="P.O."
                        placeholder="Enter Your Post Office"
                      />

                      <Input
                        type="text"
                        name="postal_district"
                        label="District"
                        placeholder="Enter Your District"
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <Input
                        type="text"
                        name="postal_state"
                        label="State"
                        placeholder="Enter State"
                      />

                      <Input
                        type="text"
                        name="postal_pincode"
                        placeholder="Enter Your Pin"
                        label="Enter Your Pin"
                      />
                    </div>
                  </div>
                )}
                <hr />

                <div className="flex justify-between">
                  <button
                    type="button"
                    className="bg-green-500 text-white py-2 px-4 rounded"
                    onClick={() => {
                      setTab(1);
                    }}
                  >
                    Previous
                  </button>
                  <button
                    disabled={isSaving || !values.certificate_delivery_type}
                    type="submit"
                    className={`${
                      values.certificate_delivery_type
                        ? "bg-blue-500"
                        : "bg-blue-300"
                    } text-white py-2 px-4 rounded`}
                  >
                    {isSaving ? "Saving..." : "Next"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default CertificateCreateStep2;
