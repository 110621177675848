import React from "react";
import { Disclosure } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { ChevronUpIcon } from "@heroicons/react/outline";
import { useGetUserQuery } from "./../store/slices/apis/authApi";
import { useEffect } from "react";
import { setAuthenticated, setUser } from "./../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
function LandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const {
    data: user,
    isLoading: isGetUserLoading,
    isFetching: isGetUserFetching,
    isSuccess: isGetUserSuccess,
    isError: isGetUserError,
    error: userError,
  } = useGetUserQuery();

  useEffect(() => {
    if (isGetUserSuccess) {
      dispatch(setAuthenticated(true));
      dispatch(setUser(user || {}));
    }
    if (isGetUserError) {
    }
  }, [user, dispatch, isGetUserLoading, isGetUserSuccess, isGetUserError]);
  if (isGetUserLoading) return <div>Loading...</div>;
  if (isLoggedIn) return navigate("/dashboard");

  return (
    <>
      <Disclosure
        as="nav"
        className="bg-gradient-to-r to-indigo-800 from-blue-500 h-14 flex justify-center"
      >
        <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div className="flex-shrink-0 flex items-center m-auto">
            <img
              src="https://erp.dibru.work/images/dibru.png"
              className="rounded-full w-10 mr-5"
              alt="Avatar"
            />

            <h1 className="text-white">
              Online Certificate Application Portal
            </h1>
          </div>
        </div>
      </Disclosure>

      <div className="p-7 min-h-screen">
        <div className=" h-full pb-5 shadow-2xl rounded-lg text-center">
          <div className="mx-auto max-w-6xl py-8 sm:py-10 lg:py-12 ">
            <div className="text-center">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                Welcome to the Certificate Portal of Dibrugarh University
              </h3>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui
                lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat
                fugiat aliqua. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Deserunt harum sed veniam iste voluptates at
                modi eos culpa deleniti rerum. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Sunt veniam cupiditate dolorem
                aspernatur, quidem pariatur nostrum voluptates quos quaerat
                fugit nihil maxime voluptatibus consectetur reprehenderit natus
                tempore vitae beatae animi nulla, iusto quisquam labore. Id
                officia deserunt iusto, consectetur dolores non repudiandae!
                Fugit iusto quod placeat debitis, cum illum saepe.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link
                  to="/student-login"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  LOG IN
                </Link>
                <Link
                  to="/register"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  SIGN UP
                </Link>
              </div>
            </div>
          </div>

          {/* DropDown Menu */}
          <div className="w-full px-4">
            <div className="mx-auto w-full max-w-xl rounded-2xl bg-white p-2">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-indigo-100 px-4 py-2 text-left text-sm font-medium text-indigo-600 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>Instructions</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-indigo-600`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      If you're unhappy with your purchase for any reason, email
                      us within 90 days and we'll refund you in full, no
                      questions asked. Lorem ipsum dolor sit amet, consectetur
                      adipisicing elit. Corrupti molestias blanditiis impedit
                      ullam voluptas? Libero illum natus eius pariatur aperiam.
                      Ipsam, ipsum inventore unde est aperiam expedita delectus
                      hic similique!
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-indigo-100 px-4 py-2 text-left text-sm font-medium text-indigo-600 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>Technical Helpline</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-indigo-600`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eveniet, esse, adipisci nobis tenetur libero quas nesciunt
                      error magni quibusdam eum ratione deserunt tempore optio
                      hic culpa soluta iure cupiditate ea?
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-indigo-100 px-4 py-2 text-left text-sm font-medium text-indigo-600 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>Important Note</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-indigo-600`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eveniet, esse, adipisci nobis tenetur libero quas nesciunt
                      error magni quibusdam eum ratione deserunt tempore optio
                      hic culpa soluta iure cupiditate ea?
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-indigo-100 px-4 py-2 text-left text-sm font-medium text-indigo-600 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>Refund and cancellation Policy</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-indigo-600`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eveniet, esse, adipisci nobis tenetur libero quas nesciunt
                      error magni quibusdam eum ratione deserunt tempore optio
                      hic culpa soluta iure cupiditate ea?
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-indigo-100 px-4 py-2 text-left text-sm font-medium text-indigo-600 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>Terms and Conditions</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-indigo-600`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eveniet, esse, adipisci nobis tenetur libero quas nesciunt
                      error magni quibusdam eum ratione deserunt tempore optio
                      hic culpa soluta iure cupiditate ea?
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-indigo-100 px-4 py-2 text-left text-sm font-medium text-indigo-600 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>Privacy Policy</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-indigo-600`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eveniet, esse, adipisci nobis tenetur libero quas nesciunt
                      error magni quibusdam eum ratione deserunt tempore optio
                      hic culpa soluta iure cupiditate ea?
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
